.small,
small {
  font-size: .875em;
}

.copyrighttext {
  align-self: stretch;
  position: relative;
  line-height: 20.4px;
}

.footer,
.footercopyright {
  display: flex;
  justify-content: flex-start;
}

.footercopyright {
  align-self: stretch;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  text-align: right;
  font-size: var(--font-size-xs);
  color: var(--hadizainalcom-gallery);
  font-family: var(--font-montserrat);
}

.footer {
  display: flex;
  margin: 0 !important;
  width: 100vw;
  position: fixed;
  padding: 4px 1rem;
  background: #0d0f1d;
  border-top: 1px solid #9acd325c;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  z-index: 998;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    display: none;
  }
}