@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@100;200;300;400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
  background-color: #11152b;
  background: #11152b;
  color: snow;
  font-family: var(--font-plus-jakarta-sans) !important;
}

:root {
  --font-montserrat: Montserrat;
  --font-plus-jakarta-sans: "Plus Jakarta Sans";
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --color-gray-100: #1e1e1e;
  --hadizainalcom-big-stone: #11152b;
  --color-gray-200: #041122;
  --color-yellowgreen: #9acd32;
  --hadizainalcom-gallery: #eee;
  --color-darkslategray: rgba(62, 65, 82, .3);
  --color-lavender: rgb(219 225 238 / .8);
  --color-white: snow;
  --color-snow: rgba(236, 242, 255, .5);
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-9xl: 28px;
  --gap-5xl: 24px;
  --gap-base: 16px;
  --padding-21xl: 40px;
  --padding-19xl: 38px;
  --padding-5xs: 8px;
  --padding-9xs: 4px;
  --padding-base: 16px;
  --padding-3xs: 10px;
  --padding-29xl: 48px;
  --padding-2xs: 11px;
  --padding-4xs: 9px;
  --padding-3xs-5: 9.5px;
  --padding-13xl: 32px;
  --padding-12xs: 1px;
  --padding-10xs: 3px;
  --padding-5xl: 24px;
  --padding-12xs-7: .7px;
  --padding-74xl: 93px;
  --br-13xl: 32px;
  --br-6xl: 25px;
  --br-11xl: 30px;
  --br-xl: 20px;
  --br-21xl: 40px;
  --br-12xs: 1px;
  --br-base: 16px;
  --br-5xs: 8px;
  --br-9xs: 4px;
  --br-lgi-5: 19.5px;
  --br-6xs: 7px
}

.swiper-button-next,
.swiper-button-prev {
  background: transparent !important;
  border: none;
  color: transparent !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #000;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-button-prev.swiper-nav-ctrl,
.swiper-button-next.swiper-nav-ctrl {
  color: snow !important;
}


.hz-lottie-container {
  position: fixed;
  display: flex;
  top: 50vh;
  left: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -10;

  #hz-logo-animation {
    position: fixed;
    width: 140vw;
    height: auto;
    transform: rotate(20deg);
    opacity: .03;
    z-index: -10
  }
}

html {
  font-family: var(--font-plus-jakarta-sans);
  font-optical-sizing: auto;
  line-height: 1.15;
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  color: #eee
}

*,
::after,
::before {
  box-sizing: border-box
}

a {
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
}

img {
  overflow: clip;
  overflow-clip-margin: content-box
}

input:-internal-autofill-selected {
  background-color: transparent !important
}

input#email,
input#password,
input#password-repeat {
  color: snow;
  padding: 1rem;
  border-radius: 4px
}


body .top-logo-button {
  position: fixed;
  display: flex;
  height: 50px;
  width: 50px;
  scale: 1 !important;
  transform: none !important;
  z-index: 99;
  justify-content: center;
  align-items: center;
}

body .top-logo {
  position: fixed;
  display: flex;
  height: 40px;
  width: 40px;
  scale: 1 !important;
  transform: none !important;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 8px 8px 0px 8px;
  background: #040404de;
  backdrop-filter: blur(4px);
  z-index: 99;
  justify-content: center;
  align-items: center;
  border: 1px outset rgba(153, 205, 50, 0);

  &:hover {
    border: 1px outset yellowgreen;
  }
}

body #top-logo-svg {
  position: fixed;
  height: auto;
  width: 24px;
  cursor: pointer;
  z-index: 99;
  scale: 1 !important;
  transform: none !important;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    filter: brightness(1.2);
    transition: all 0.3s;
  }

  @media screen and (max-width: 960px) {
    position: relative;
  }
}

.pagetop {
  top: 0;
  position: relative;
  transition: all 0.3s;
}

body.drawer-open {
  overflow: hidden;
}

.main-bg.lock-scroll {
  overflow: hidden;
  height: 0;
}

.skeleton {
  background-color: #e0e0e0;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 500px 0;
  }
}

.dBox {
  height: 100%;
  overflow-y: auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* Login */

.css-953pxc-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important
}

.css-953pxc-MuiInputBase-root-MuiInput-root::after {
  border-bottom: 1px solid yellowgreen !important
}

.css-1u4wvdd-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1u4wvdd-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: yellowgreen !important;
}

.login-container {
  background: #040404;
  padding: 50px;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    padding: 20px;
    width: 100%;
    max-width: 320px;
    border-top: 0px outset rgba(253, 253, 253, 0.24) !important;
    border-bottom: 0px outset rgba(253, 253, 253, 0.24) !important;

  }
}

.link-text {
  color: snow;
  transition: all 0.3s;

  &:hover {
    color: yellowgreen !important;
    transition: all 0.3s;
  }
}

/* home slider */
.iframe-slider {
  height: 95vh;
  @media screen and (max-width: 768px) {
    height: 90vh !important;
  }
}


.slider-mobile-thumbpath {
  position: absolute;
  height: 600px;
  width: 170px;
  background: #13152d5e;
  margin-top: 24px;
  margin-left: 12px;
}

#slide1-h1,
#slide2-h1,
#slide3-h1 {
  position: relative;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1.3;
  margin-left: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 34px !important;
    /* text-transform: uppercase; */
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  #twentyplus-sub-text {
    top: 0 !important;
  }
}
.slider-video {
  display: block;
  min-width: fit-content;

  .slide-video-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 50vh;
    max-height: 50vh;
    width: fit-content !important;
    min-width: fit-content !important;
    background: #0f132d;
    overflow: hidden;

    video {
      width: auto;
      height: 50vh;
      min-height: 50vh;
      object-fit: contain;
    }
  }
}

.img70vh {
  display: block;
  width: fit-content !important;
  max-width: fit-content !important;
  height: 70vh;
  object-fit: contain;
    overflow: hidden;

  img {
    height: 70vh;
    width: fit-content !important;
    max-width: fit-content !important;
    object-fit: contain;
  }
}

.num-desctext {
  font-size: 14px;

  @media screen and (max-width: 998px) {
    font-size: 14px;
  }
}

.w150 {
  text-align: start;

  @media screen and (max-width: 768px) {
    width: 150px;
    text-align: end;

  }
}

@media screen and (max-width: 1200px) {

  [data-overlay-dark='1']:before,
  [data-overlay-light='1']:before {
    opacity: 0.5 !important;
    transition: all 0.3s;
  }

  #slide-p {
    color: #eee;
  }
}


/* Numbers homepage */
.col-lg-3.col-md-3.exp-number-col {
  min-width: 25%;

  @media screen and (max-width: 1400px) {
    min-width: 50%;
    margin: 1rem 0;
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: start;
  }
}

/* Mui */
.MuiStack-root.scrollMainContainer {
  position: relative;
  margin: auto !important;
  padding: 0 !important;
  top: 2rem;
  left: 0px;
  right: 0px;
  display: flex;
  background: #0000003b;
  height: fit-content;
  min-height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
}

.MuiPaper-root {
  background-color: transparent
}

.MuiPaper-root.MuiDrawer-paper {
  background: #000000b5 !important;
  /* background: #484a4d9f !important; */
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid yellowgreen;
  margin: 1rem;
  border-radius: 4px;
  max-height: calc(100% - 2rem)
}

.drawerButtonLinks {
  text-transform: capitalize
}

.dTextButton {
  color: #eee
}

.MuiListItemIcon-root.dListItemIcon {
  margin: 0;
  max-width: 170px;
  min-width: 0px !important;
}

.MuiListItemIcon-root.dListItemButton {
  max-width: 170px;
  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  padding: 6px 16px;
  height: auto;
}

.dListItemButton,
.dListItemIcon {
  color: #eee !important;
  /* max-width: 192px; */
  background: #040404 !important;
  margin: 2px;
  border-radius: 4px;
  transition: all .3s;

  &:hover {
    background: #000;
    transition: all .3s
  }
}

.MuiList-root.MuiList-padding.dFlexList {
  display: flex;
  gap: 1rem;
  height: 100vh;
  max-height: 88vh;
  overflow-y: scroll;
}

.dBox {
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: fit-content;
  margin: auto;
  overflow: visible
}

.dDrawer {
  border: 0 solid yellowgreen;
  background-color: transparent !important
}

.MuiButtonBase-root.MuiButton-root.dNavFullButton {
  width: calc(100vw - 7rem) !important;
  background: #090c1747 !important;
  display: flex !important;
  border-radius: 0 !important;
  justify-content: flex-start !important;

  &:hover {
    background: #040404 !important;
    transition: all .3s
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-5.dNavFullButton-col4 {
  display: flex;
  overflow: hidden;
  gap: 4px;
  width: 100%;
  background: #040404;

  & img {
    width: 100%;
    /* max-width: 120px; */
    height: 80px;
    object-fit: cover;
  }
}

.dNavFullButton.dNavFullButton-imgButton {
  border: 1px solid #040404;
  filter: brightness(1);
  overflow: hidden;
  max-height: 120px;
  min-width: 200px;
  width: calc(100% / 5);
  transition: all 0.3s;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s
  }

  & span {
    display: flex;
    position: relative;
    font-weight: 200;
    letter-spacing: 0.5px;
    line-height: normal;
    padding: 8px;
    background: rgba(0, 0, 0, 1);
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: smaller;
  }

  &:hover {
    border: 1px solid yellowgreen;
    filter: brightness(1.1);
    transition: all 0.3s;

    & img {
      scale: 1.1;
      transition: all 0.3s
    }
  }
}

.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  position: absolute;
  /* position: fixed; */
  bottom: 42px;
  left: 40px;
  right: 40px;
  max-height: 88vh;
  /* overflow: hidden; */
  overflow: visible;

  @media screen and (max-width: 768px) {
    width: calc(100% - 16px);
    left: 0px;
    right: 0px;
    bottom: 56px;
    margin: auto;
  }
}

.MuiDrawer-paper.MuiDrawer-paperAnchorTop {
  position: fixed;
  top: 36px;
  left: 36px;
  right: 36px
}

.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  position: fixed;
  top: 36px;
  right: 36px;
  max-height: calc(100% - 104px);
  max-width: 208px;
  padding: 0
}

.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  position: fixed;
  top: 36px;
  left: 36px;
  max-height: calc(100% - 104px);
  max-width: 208px;
  padding: 0
}

.MuiBackdrop-root.MuiModal-backdrop {
  bottom: 0;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 110;
  background-color: #00000066;
  transition: all 0.3s;
}

.MuiBackdrop-root.MuiModal-backdrop .buttonmenuClose {
  transition: all 0.3s;
}

.MuiBackdrop-root.MuiModal-backdrop .buttonmenuClose:hover {
  transform: scale(1.1) rotate(360deg);
  filter: brightness(1.2);
  transition: all 0.3s;
}

#menuDrawerActiveBg {
  z-index: 1100;
  top: 0;
}

#tooltip-mainmenu {
  position: absolute !important;
  margin-left: -3rem !important;
  height: 50px;
  opacity: 1 !important;
  visibility: visible !important;
}



.tooltip:hover .tooltiptext {
  visibility: visible;
}

.MuiButton-root.MuiLoadingButton-root.Mui-disabled {
  color: rgb(145 145 145 / 36%);
  border: 1px solid rgb(145 145 145 / 36%)
}

.MuiInputBase-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #b4b9c8 inset !important
}

.buttonText {
  color: #eee;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;

  &:hover {
    background-color: rgba(131, 199, 37, .04)
  }
}

.buttonContained {
  color: #eee;
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize
}

.buttonOutlined {
  color: #eee;
  font-size: 1rem;
  font-weight: 400;
  border-color: #eee;
  text-transform: capitalize
}

#split-button-menu {
  background: #05070a9f;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid yellowgreen;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  color: #eee
}

/*  carousel-react */
.serv-carousel {
  /* overflow: hidden; */
  position: relative;
  width: 100vw;
  user-select: none;
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  max-width: 400px;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.carousel-slide {
  min-width: 100%;
  box-sizing: border-box;
  transition: transform 0.5s ease-in-out;
}

/* End react Swiper */

.snow {
  color: snow;
}