@keyframes slide-in-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.vectorrazorIcon {
  width: 200px;
  height: 54px;
  bottom: 12px;
  left: calc(50% - 124px);
  object-fit: contain;
  opacity: 1;
  filter: brightness(1.2);
  z-index: 0;
  user-select: none;
}

/* .vectorrazorIcon.animate {
  animation: 0.3s ease 0.3s 1 normal forwards slide-in-bottom;
} */

.bgrazor,
.vectorrazorIcon,
.vectorrazorIcon1 {
  margin: 0 !important;
  position: absolute;
  user-select: none;
}

.vectorrazorIcon1 {
  width: 230px;
  height: 62px;
  bottom: 10px;
  left: calc(50% - 99px);
  opacity: 1;
  z-index: 1;
  user-select: none;
}

/* .vectorrazorIcon1.animate {
  animation: 0s ease 0s 1 normal forwards slide-in-bottom;
} */

.bgrazor {
  width: 360px;
  height: 74px;
  bottom: -14px;
  left: calc(50% - 175px);
  display: flex;
  scale: 1.18 1.2;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 999;
  position: fixed;
  user-select: none;
}