@keyframes custom-animation {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scaleX(0);
  }
  to {
    opacity: 1;
    transform-origin: center center;
    transform: scaleX(1);
  }
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.bubblesubtract01Icon {
  width: 75px;
  position: absolute;
  margin: 0 !important;
  top: -12px;
  left: -25.5px;
  height: 37.8px;
  display: none;
  z-index: 0;
}
.iconabout {
  width: 13px;
  position: relative;
  height: 22px;
  top: -2px;
  z-index: 1;
}
.buttonNavmain, .buttonNavmain:active .bubblesubtract01Icon,
.buttonNavmain.active .bubblesubtract01Icon {
  display: flex;
  transition: all 0.3s;
  transform-origin: center center;
  justify-content: center;
}
.buttonNavmain {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
    height: 30px;
      width: 24px;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.buttonNavmain:hover {
  scale: 1;
  transition: all 0.3s;
  filter: brightness(1.1);
}
.buttonNavmain:active,
.buttonNavmain.active {
  transition: all 0.3s;
  transform-origin: center center;
  user-select: none;
  -webkit-user-select: none;
  /* overflow: visible; */
}
.buttonNavmain:active .bubblesubtract01Icon,
.buttonNavmain.active .bubblesubtract01Icon {
  animation: 0.1s ease 0s 1 normal none custom-animation;
  opacity: 1;
  scale: 0.95;
  top: -8.5px;
  position: absolute;
  left: -26px;
  user-select: none;
}
.buttonNavmain:active .iconabout,
.buttonNavmain.active .iconabout {
  width: 34px;
  height: 34px;
  border-radius: var(--br-xl);
  animation: 0.1s ease 0s 1 normal none slide-in-bottom;
  opacity: 1;
  background: #040404;
  scale: 1.18;
  padding: 4px;
  margin-top: -14px;
  border: 2px solid;
  border-color: #9acd32;
  border-style: outset;
  overflow: clip;
  filter: drop-shadow(2px 4px 6px black);
}
