.menuwrapper,
.vectorbgIcon {
  margin: 0 !important;
  position: absolute;
  user-select: none;
}

.vectorbgIcon {
  width: 377px;
  top: 10px;
  left: -13px;
  height: 60px;
  scale: 1.2 1;
  z-index: 0;
  user-select: none;
}

.menuwrapper {
  width: 100%;
  top: calc(50% - 12px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-29xl);
  box-sizing: border-box;
  gap: 1.7rem;
  z-index: 1;
  left: -2px;
  right: 0;
  user-select: none;
}

.navbottomcontainer,
.navmain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  user-select: none;
}

.navbottomcontainer {
  width: 350px;
  height: 57px;
  align-items: center;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-3xs);
  user-select: none;
}

.navmain {
  margin: 0 !important;
  position: fixed;
  bottom: 0;
  left: calc(50% - 175px);
  align-items: flex-start;
  padding: var(--padding-base) 0 0;
  z-index: 1000;
  user-select: none;
}