

.iconmenumain {
  width: 42px;
  position: relative;
  height: 30px;
  opacity: 1 !important;
  visibility: visible !important;
  bottom: 23px;
  color: aliceblue;

  &:focus {
    outline: none;
    opacity: 1 !important;
  }
}

.buttonmenu,
.buttonmenumain {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonmenumain {
  position: absolute;
  height: 11px;
  opacity: 1 !important;
  width: 15px;
  left: 2px;
  top: 10px;
  color: aliceblue;

  &:hover,
  :focus,
  :active {
    opacity: 1 !important;
    transition: all 0.3s;
  }
}


.buttonmenu {
  cursor: pointer;
  border: 0;
  padding: var(--padding-9xs) 0 0;
  background-color: transparent;
  width: 24px;
  box-sizing: border-box;
  width: 20px;
  height: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
}

.buttonmenuClose {
  cursor: pointer;
  display: flex;
  /* border: 1.5px solid yellowgreen; */
  border-radius: 50%;
  border-style: outset;
  padding: 11px 11px;
  background: radial-gradient(red, #410000);
  width: 20px;
  height: 20px;
  top: 24px;
  right: 24px;
  color: aliceblue;
  left: calc(50% - 116px);
  transform: translate(0px, -5px);
  scale: 1.1;
  position: absolute;
  z-index: 130000;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 1px 8px #cddc3936; */
  transition: all 0.3s;

  &:hover {
    /* transform: scale(1) rotate(180deg); */
    filter: brightness(1.2);
    transition: all 0.3s;
  }
}

