@keyframes slide-in-bottom {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes custom-animation {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scaleX(0);
  }

  to {
    opacity: 1;
    transform-origin: center center;
    transform: scaleX(1);
  }
}

.bubblesubtract01Icon {
  width: 75px;
  position: absolute;
  margin: 0 !important;
  top: -12px;
  left: -25.5px;
  height: 37.8px;
  display: none;
  z-index: 0;
}

.iconhome {
  width: 15px;
  position: relative;
  border-radius: var(--br-12xs);
  height: 15px;
  top: 0px;
  z-index: 1;
}

.buttonNavmain {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
    height: 30px;
      width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.buttonNavmain:hover {
  scale: 1;
  transition: all 0.3s;
  filter: brightness(1.1);
}

.buttonNavmain,
.buttonNavmain:active,
.buttonNavmain.active {
  transition: all 0.3s;
  transform-origin: center center;
}

.buttonNavmain:active,
.buttonNavmain.active {
  user-select: none;
}

.buttonNavmain:active .iconhome,
.buttonNavmain.active .iconhome {
  width: 34px;
  height: 34px;
  border-radius: var(--br-xl);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  animation: 0.1s ease 0s 1 normal none slide-in-bottom;
  opacity: 1;
  background: #040404;
  scale: 1.18;
  padding: 8px 2px 8px 2px;
  margin-top: -10px;
  position: absolute;
  border: 2px solid;
  border-color: #9acd32;
  border-style: outset;
  overflow: clip;
}

.buttonNavmain:active .bubblesubtract01Icon,
.buttonNavmain.active .bubblesubtract01Icon {
  display: flex;
  animation: 0.1s ease 0s 1 normal none custom-animation;
  opacity: 1;
  scale: 0.95 1;
    transition: all 0.3s;
    top: -7.5px;
  transform-origin: center center;
  position: absolute;
  justify-content: center;
  left: -26px;
  user-select: none;
}